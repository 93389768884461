import { EuiBadge, EuiBadgeGroup, EuiBasicTable, EuiButton, EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiFlyout, EuiFlyoutFooter, EuiFlyoutHeader, EuiHealth, EuiIcon, EuiPanel, EuiSearchBar, EuiSelectable, EuiSpacer, EuiSuperSelect, EuiText, EuiTitle, EuiToolTip } from '@elastic/eui';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react'
import Api from '../../Api';
import DetailsFlyout from './DetailsFlyout';
import NotesFlyout from '../CommonWidgets/NotesFlyout';
import moment from 'moment';


const VulnTable = ({
    data, 
    pageIndex = 0, 
    pageSize = 50,
    totalItemCount = 0, 
    setPageIndex = () => { },
    setPageSize = () => { },
    handleSearchChange = ()=>{},     
    initialSearch=false, 
    sortInfo = {
        sortField: "Address",
        sortDirection: "asc",
        handleSortChange: (sortField, sortDirection) => {},    
    },
    searchFilterData={},
    searchFilterHandler,
    
    rehydrateData= ()=>{},
    currentFilters = {},
 }) => {
    
    const [selectedStartDate, changeSelectedStartDate] = useState(currentFilters.startDateFilter > 0 ? moment.unix(currentFilters.startDateFilter) : null)
    const [selectedEndDate, changeSelectedEndDate] = useState(currentFilters.endDateFilter > 0 ? moment.unix(currentFilters.endDateFilter) : null)
        
    const [TableData, changeTableData] = useState([])
    const [notesFlyoutVisible, changeNotesFlyoutVisible]=useState(false)
    const [detailsFlyoutVisible, changeDetailsFlyoutVisible]=useState(false)
    const [searchFlyoutVisible, changeSearchFlyoutVisible]=useState(false)
    
    const [selectionValues, setSelectionValues] = useState([])
    const [selectedCveDetails, changeSelectedCveDetails]=useState({})
    const [selectedServiceDetails, changeSelectedServiceDetails]=useState({})    
    const [selectedNoteDetails, changeSelectedNoteDetails]=useState({
        id: null        
    })
    const [sortField, setSortField] = useState(sortInfo.sortField);
    const [sortDirection, setSortDirection] = useState(sortInfo.sortDirection);

    const [selectedSearchType, changeSelectedSearchType] = useState()
    const [selectedSearchCriteria, changeSelectedSearchCriteria] = useState([])

    const [searchFilterInfo, changeSearchFilterInfo] = useState({
        "Severity": {
            options: [],
            valueOfSelected: []
        },
        "TutelaSeverity": {
            options: [],
            valueOfSelected: []
        },
        "Address":{
            options: [],
            valueOfSelected: []
        },
        "CVE": {
            options: [],
            valueOfSelected: []
        },
        "Version":{
            options: [],
            valueOfSelected: []
        }, 
        "Port":{
            options: [],
            valueOfSelected: []
        },
        "Tag":{
            options: [],
            valueOfSelected: []
        }
    })

    useEffect(()=>{
        let result=searchFilterInfo
        Object.keys(searchFilterData).forEach(k=>{
            result[k]={
                options: [],
                valueOfSelected: []
            }
            searchFilterData[k].forEach(entry=>result[k]["options"].push({label:entry.id}))            
            result[k].handleSearchFilterChange = (value)=>searchFilterHandler([[value, k.toLowerCase()+"Filter"]])            
        })
        changeSearchFilterInfo(result)
    },[searchFilterData, searchFilterHandler])

    const handleUpdateNote = noteText => {       
        debugger
        Axios.post(Api.updateNote, {
            ID: selectedNoteDetails.id,            
            Note: noteText
        })

        const tableRowToUpdate = TableData.findIndex(elem => (elem.Address === selectedNoteDetails.address && 
            elem.Port === selectedNoteDetails.port && 
            elem.CveID=== selectedNoteDetails.cve ))

        
        const tempTable = [...TableData]
        tempTable[tableRowToUpdate].Notes = noteText
        changeTableData(tempTable)
        

        changeSelectedNoteDetails({
            id: null            
        })

        changeNotesFlyoutVisible(false)
    }
    
    const handleToggleResolved = (newNotes) => {    
        
        let pendingNotes = newNotes.map(note => {
            return Axios.post(Api.updateResolved, note)
        })

        Promise.all(pendingNotes).then( () => {    
            alert("Issue(s) Status Updated")
            rehydrateData()
        })
    
        
    }

    useEffect(()=>{

        changeTableData(data)

    },[data])

    const tableSelectionValue = {
        onSelectionChange: (selection) => {
            setSelectionValues(selection)
        }        
      };

    const handleMassNote = () => {

        let noteText = window.prompt("Insert New Note:")

        const modifiedNotes = selectionValues.map(item => {
            return Axios.post(Api.updateNote, {
                id: item.id,
                Note: noteText
            })
        })

        Promise.all(modifiedNotes).then(()=>{
            alert("Note(s) Added")
            rehydrateData()
        });

        
    }

    const handleMassAddTag = () => {

        let tagName = window.prompt("Insert Tag To Add:")

        let requests = selectionValues.map(item => {

            return  Axios.post(Api.createCveTag, {
                Name: tagName,
                ID: item.id  
            })                        
                
        })

        Promise.all(requests).then(function(values) {
            alert("Tags Added")
            rehydrateData()
        });
    }

    const handleMassRemoveTag = () => {

        let tagName = window.prompt("Insert Tag To Remove:")

        let requests =  selectionValues.map(item => {
            
            return Axios.post(Api.deleteCveTag, {
                Name: tagName,
                ID: item.id  
            })                        
                
        })

        Promise.all(requests).then(function(values) {
            alert("Tags Removed")
            rehydrateData()
        });
    }

    const handleMassResolve = () => {
        handleToggleResolved(selectionValues.map(item => ({
            Id: item.id,
            Resolved: true            
        })))        
    }

    const handleMassPending = () => {
        handleToggleResolved(selectionValues.map(item => {
            return {
                ID: item.id,
                Resolved: false
            }
        }))
    }

    const onTableChange = ({ page = {}, sort = {} }) => {
        const { index: pageIndex, size: pageSize } = page;     
        setPageIndex(pageIndex);
        setPageSize(pageSize)
    
        const { field: sortField, direction: sortDirection } = sort;
        setSortField(sortField);
        setSortDirection(sortDirection);
    };

    useEffect(()=> {
        let translatedSortField = sortField
        switch (sortField) {
            case "Host":
                translatedSortField = "Address"
                break;
            case "CVE":
                translatedSortField = "CveID"
                break;            
            default:
                break;
        }
        sortInfo.handleSortChange(translatedSortField, sortDirection)
    }, [sortField, sortDirection, sortInfo])


    const startDateChange = (e) => {
        changeSelectedStartDate(e)
    }

    const endDateChange = (e) => {
        changeSelectedEndDate(e)
    }

    return <Fragment>
            <EuiFlexGroup gutterSize="m" style={{width: "100%"}}>
                <EuiFlexItem grow={9} >
                    <EuiSearchBar
                        fullWidth                         
                        placeholder="Search..." 
                        onChange={handleSearchChange}
                        defaultQuery={initialSearch}
                    />
                </EuiFlexItem>

                <EuiFlexItem grow={3}>
                <EuiDatePicker
                    showTimeSelect
                    selected={selectedStartDate}
                    onChange={
                        startDateChange
                    }
                    placeholder="First Seen Filter"
                />                
                </EuiFlexItem>    

                <EuiFlexItem grow={3}>
                <EuiDatePicker
                    showTimeSelect
                    selected={selectedEndDate}
                    onChange={
                        endDateChange
                    }
                    placeholder="Last Seen Filter"
                />                
                </EuiFlexItem>    

                {(selectedEndDate || selectedStartDate) && <EuiFlexItem>
                    <EuiButton fill onClick={()=>{
                        let changedDateFilters = []
                        selectedStartDate > 0 && changedDateFilters.push([selectedStartDate.unix(), "startDateFilter"])
                        selectedEndDate > 0 && changedDateFilters.push([selectedEndDate.unix(), "endDateFilter"])
                        if (changedDateFilters.length > 0)  {
                            searchFilterHandler( changedDateFilters )
                        }
                }}> Submit </EuiButton>
                </EuiFlexItem>
                }      

            </EuiFlexGroup>
            <EuiFlexGroup gutterSize="m">
                {
                    Object.keys(searchFilterInfo).map(searchFilterKey => <EuiFlexItem>
                        <EuiButton
                            onClick={()=>{            
                                changeSelectedSearchType(searchFilterKey)
                                changeSearchFlyoutVisible(true)
                            }}
                            //onClick={(value)=>searchFilterInfo[searchFilterKey].handleSearchFilterChange(value)}
                        >
                            Filter By {searchFilterKey === "Port" ? "Port/Package" : searchFilterKey}
                        </EuiButton>
                    </EuiFlexItem>)
                }                
            </EuiFlexGroup>
            {selectionValues.length > 0 ? <>
                <EuiButton fill color="primary" onClick={handleMassResolve}>
                    Mark as Resolved
                </EuiButton>
                <EuiButton fill color="primary" style={{marginLeft: 10, marginTop: 5}} onClick={handleMassPending}>
                    Mark as Pending
                </EuiButton>
                <EuiButton fill color="primary" style={{marginLeft: 10, marginTop: 5}} onClick={handleMassNote}>
                    Insert Note
                </EuiButton>
                <EuiButton fill color="primary" style={{marginLeft: 10, marginTop: 5}} onClick={handleMassAddTag}>
                    Add Tag
                </EuiButton>
                <EuiButton fill color="primary" style={{marginLeft: 10, marginTop: 5}} onClick={handleMassRemoveTag}>
                    Remove Tag
                </EuiButton>
                <EuiSpacer />
            </>
                :null}
            <EuiBasicTable
                itemId="id"
                items={TableData}
                onChange={onTableChange}                
                columns={[
                    {
                        width: "100px",
                        field: 'Resolved',
                        name: 'Resolved',
                        dataType: 'boolean',
                        render: resolved => {
                            const color = resolved ? 'success' : 'danger';
                            const label = resolved ? 'Resolved' : 'Pending';
                            return <EuiHealth color={color}>{label}</EuiHealth>;
                        }
                    },
                    {
                        field: 'EntityType',
                        name: 'Type',
                        width: "50px",
                        render: (item) => {
                            return <>
                                {item==="container" && <EuiToolTip content="Container"><EuiIcon color="primary" type="layers"></EuiIcon></EuiToolTip>}
                                {item==="application" && <EuiToolTip content="Application (Library Dependancy)"><EuiIcon color="primary" type="database"></EuiIcon></EuiToolTip>}
                                {item==="host_agent" && <EuiToolTip content="Host Agent(Installed Software)"><EuiIcon color="primary" type="compute"></EuiIcon></EuiToolTip>}
                                {item==="network_scan" && <EuiToolTip content="Network Scan"><EuiIcon color="primary" type="indexMapping"></EuiIcon></EuiToolTip>}
                            </>                            
                        },
                        sortable: true,
                    },
                    {
                        field: 'Hostname',
                        name: 'Host',
                        width: "100px",
                        sortable: true,
                    },
                    {
                        field: 'Port',
                        name: 'Port',
                        width: "50px",
                        sortable: true,
                    },
                    {
                        field: 'Service',
                        name: 'Service',       
                        sortable: true,                 
                    },
                    {
                        field: 'Product',
                        name: 'Product',        
                        sortable: true,                
                    },
                    {
                        field: 'Version',
                        name: 'Version',       
                        sortable: true,                 
                    },
                    {
                        field: 'Severity',
                        name: 'Severity',
                        sortable: true,
                    },
                    {
                        field: 'CveID',
                        name: 'CVE',
                        sortable: true,
                    },
                    {
                        width: "80px",
                        field: 'CS_Score',
                        name: 'Severity Score',
                        render: score => {
                            let color
                            let label
                            let content

                            if (score.score < 40){
                                color = '#ffcc66'
                                label = 'Low'
                                content = label
                            } else if (score.score < 70){
                                color = '#ff9900'
                                label = 'Medium'
                                content = label
                            } else if (score.score < 90){
                                color = '#ff0000'
                                label = 'High'
                                content = label
                            } else {
                                color = '#cc0066'
                                label = 'Critical'
                                content = label
                            }
                            /*
                                color = 'success'
                                label = 'Yes'
                                let exploitNumber = exploitable
                                if (exploitNumber.startsWith('https://') || exploitNumber.startsWith('http://')){
                                    content=<a target='_blank' href={`${exploitNumber}`}>{label}</a>
                                } else if (exploitNumber.startsWith('EXPLOIT-DB:')){
                                    exploitNumber = exploitNumber.split(':')[1]
                                    content=<a target='_blank' href={`https://www.exploit-db.com/exploits/${exploitNumber}`}>{label}</a>
                                }
                                
                            }
                            */
                            
                            return <EuiToolTip
                                position="right"
                                title="Severity Score"
                                content={<>
                                <ul>
                                    <li>
                                        EPSS: {score.epss} (out of 1.0) 
                                    </li>
                                    <li>
                                        CVE Score: {score.cve_score} (out of 10.0)
                                    </li>
                                    <li>
                                        ExploitDB: {score.exploitdb == ""? "N/A" : score.exploitdb}
                                    </li>
                                    <li>
                                        <strong>
                                            Aggregated Score: {score.score}
                                        </strong>
                                    </li>                                                                                                        
                                </ul>
                                </>
                                
                                }
                            >
                                <EuiHealth color={color}>{content}</EuiHealth>
                            </EuiToolTip>;
                        }
                    },
                    {
                        width: "100px",
                        field: 'ScanCounter',
                        name: 'Last Seen',
                        render: timestamp => {
                            return (new Date(timestamp*1000)).toLocaleDateString("en-UK");
                        }
                    },
                    {
                        field: 'CveDescription',
                        name: 'Description',
                        width: "25%",
                    },
                    {
                        field: 'Tags',
                        name: 'Tags',
                        width: "150px",
                        actions: [{
                            name: "Tags",
                            description: "Tags",
                            render: (item) => <EuiPanel style={{ maxWidth: 150 }}>
                                <EuiBadgeGroup gutterSize="s">
                                    {
                                        item.Tags && item.Tags.split(':::').filter(tag => tag!=="").map(tag => <EuiBadge
                                                iconType="cross"
                                                iconSide="right"
                                                onClickAriaLabel = "Remove Tag"
                                                iconOnClick={() => {
                                                    Axios.post(Api.deleteCveTag, {
                                                        Name: tag,
                                                        ID: item.id  
                                                    }).then(resp=>{
                                                        alert("Tag Removed!")
                                                        rehydrateData()
                                                    }).catch(err=>{
                                                        alert("Could not remove tag!")
                                                        console.log(err)
                                                    })
                                                }}
                                            >
                                                {tag}
                                            </EuiBadge>
                                        )
                                    }
                                    
                                    <EuiBadge
                                        iconType="plusInCircle"
                                        iconSide="right"
                                        onClickAriaLabel = "Add Tag"
                                        onClick={() => {
                                            let newtag = window.prompt("Insert a Tag:")
                                            
                                            if (!newtag) return
                                            if (newtag.trim() === "") return

                                            Axios.post(Api.createCveTag, {
                                                Name: newtag,
                                                ID: item.id  
                                            }).then(resp=>{
                                                alert("Tag Created!")
                                                rehydrateData()
                                            }).catch(err=>{
                                                alert("Could not create tag!")
                                                console.log(err)
                                            })
                                        }}
                                    >
                                        Add Tag
                                    </EuiBadge>
                                </EuiBadgeGroup>
                            </EuiPanel>     
                        }],                   
                    },
                    {
                        actions: [{
                            name: "View Notes",
                            description: "View Notes",
                            render: (item) => {
                                return <EuiButton
                                        style={{margin: 5}}
                                        iconSide="left" 
                                        iconType="documentEdit" 
                                        onClick={()=>{
                                            changeSelectedNoteDetails({
                                                id: item.id,
                                                text: item.Notes})
                                            changeNotesFlyoutVisible(true)
                                        }} 
                                    >
                                        View Notes
                                </EuiButton>
                              }
                        },{
                            name: "Toggle Resolved",
                            description: "Toggle Resolved",
                            render: item => <EuiButton 
                                        iconSide="left"
                                        iconType="checkInCircleFilled"
                                        style={{margin: 5}}
                                        onClick={() => 
                                            handleToggleResolved([{
                                                Resolved: !item.Resolved,
                                                Id: item.id
                                            }])
                                        }
                                    >
                                        Toggle Resolved
                                    </EuiButton>
                        },{
                            name: "Additional",
                            description: "Additional Info",
                            render: item => {
                                return <EuiButton
                                        iconSide="left"
                                        iconType="expand"
                                        style={{margin: 5}}
                                        onClick={()=>{
                                            changeSelectedCveDetails(item.CveDetails)
                                            changeSelectedServiceDetails(item.ServiceDetails)
                                            changeDetailsFlyoutVisible(true)
                                        }}
                                    >
                                    Additional Details
                                  </EuiButton>;
                              }
                        }]
                    }
                ]}
                pagination={{
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                    pageSizeOptions: [250, 200, 100, 50],
                    totalItemCount:totalItemCount,
                    //hidePerPageOptions: true
                }}
                isSelectable={true}
                selection={tableSelectionValue}
                sorting = {{
                    sort: {
                        field: sortField,
                        direction: sortDirection,
                    }
                }}
            />
            {notesFlyoutVisible && <NotesFlyout handleVisibility={changeNotesFlyoutVisible} noteText={selectedNoteDetails.text} handleUpdateNote={handleUpdateNote} />}
            {detailsFlyoutVisible && <DetailsFlyout handleVisibility={changeDetailsFlyoutVisible} cveDetails={selectedCveDetails} serviceDetails={selectedServiceDetails} />}
            {searchFlyoutVisible && (
                <EuiFlyout
                    ownFocus
                    onClose={() => changeSearchFlyoutVisible(false)}
                    aria-labelledby="selectableFlyout"
                >
                <EuiSelectable
                    aria-label="Search Flyout"
                    searchable
                    options={searchFilterInfo[selectedSearchType].options}
                    onChange={(value)=>{
                        changeSearchFilterInfo({
                            ...searchFilterInfo,
                            [selectedSearchType]: {
                                ...searchFilterInfo[selectedSearchType],
                                options: value
                            }
                        })
                        let selected = value.filter(datum => datum.checked=="on").map(e => e.label)
                        changeSelectedSearchCriteria(selected)
                    }}
                    height="full"
                >
                    {(list, search) => (
                    <>
                        <EuiFlyoutHeader hasBorder>
                        <EuiTitle size="m">
                            <h2 id="selectableFlyout">Search</h2>
                        </EuiTitle>
                        <EuiSpacer />
                        {search}
                        </EuiFlyoutHeader>
                        <EuiSpacer size="xs" />
                        {list}
                    </>
                    )}
                </EuiSelectable>
                <EuiSpacer size="xs" />
                <EuiFlyoutFooter>
                    <EuiButton 
                        onClick={()=>searchFilterInfo[selectedSearchType].handleSearchFilterChange(selectedSearchCriteria)}
                        fill
                    >
                        Submit
                    </EuiButton>
                </EuiFlyoutFooter>
                </EuiFlyout>
            )}
        </Fragment>
}

export default VulnTable